.error-container {
  height: 100vh;
}
.error-card-title {
  display: flex;
  justify-content: center;
  padding: 20px 0 15px 0;
  font-size: 2rem;
  font-weight: 500;
  text-decoration: underline;
  color: var(--clr-primary);
  font-family: "Poppins", sans-serif;
}
.error-text {
  font-size: 1.25rem;
  line-break: strict;
}
