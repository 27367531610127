.task-description {
  font-size: 14px;
  opacity: 0.8;
}
.reply-btn,
.send-btn {
  font-size: 20px;
  color: var(--clr-primary);
}
.reply-container {
  animation-name: dropdown-animate;
  animation-duration: 0.2s;
  animation-fill-mode: both;
}
.reply-div {
  border: 1px solid var(--clr-border-primary);
  border-radius: 4px;
}
.reply-div:focus-within {
  border-color: var(--clr-border-input-focus);
  outline: 0;
  box-shadow: var(--box-shadow-input-focus);
}
.reply-input {
  background-color: var(--clr-light);
  resize: none;
}
.reply-input:focus {
  background-color: var(--clr-light);
  outline: 0;
}
.report-status {
  min-width: 95px;
}
