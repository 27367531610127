/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
:root {
  scroll-behavior: smooth;
  --clr-primary: #012970;  /* color of page title and  icon*/
  --clr-secondary: #f6f9ff; /* background of main element */
  --clr-light: #ffffff;  /* background of cards, header and navbar */
  --clr-dark: #000000;
  --clr-highlight: #4154f1;  /* highlight color for dashboard card icons and active tab of navbar */
  --clr-highlight-2: #b2caf5; /* highlights the icon of nav item in navbar and send button in announcement and chat page */
  --clr-text-primary: #444444;
  --clr-neutral: #e6e6e6; /* used in tile background of calendar and border */
  --clr-neutral-2: #757575; /* used in tile background of calendar */
  --clr-calendar-btn-disabled: #f0f0f0;

  /* background */
  --modal-bg: rgba(0, 0, 0, 0.5);
  --filter-bg: #e7e8eb;
  --footer-bg: #303A46;
  --btn-primary-bg: #4154f1;
  --btn-secondary-bg: #6e757c;
  --btn-danger-bg: #d92e2e;

  /* scrollbar color */
  --clr-scrollbar: #aab7cf;

  /* border color */
  --clr-border-primary: #dee2e6;
  --clr-border-input-focus: #86b7fe;

  /* alert color */
  --clr-success-alert-bg: #d1e7dd;
  --clr-success-alert-text: #0a3622;
  --clr-error-alert-bg: #f8d7da;
  --clr-error-alert-text: #58151c;

  /* box-shadow */
  --box-shadow-primary: 0px 0 30px rgba(1, 41, 112, 0.1);
  --box-shadow-input-focus: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

body, #root, .App {
  font-family: "Open Sans", sans-serif;
  background: var(--clr-secondary);
  color: var(--clr-text-primary);
  min-height:calc(100vh - 60px);
}

a:not(.button) {
  color: var(--clr-highlight);
  text-decoration: none;
}

a:not(.button):hover {
  color: var(--clr-primary);
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Nunito", sans-serif;
  margin: 0;
}

@media (max-width: 1199px) {
  #main {
    padding: 20px;
  }
}

/* To remove arrows of input[] */
/* Chrome, Safari, Edge, Opera  */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/*--------------------------------------------------------------
# Override some default Bootstrap stylings
--------------------------------------------------------------*/

/* Light Backgrounds */
.bg-primary-light {
  background-color: #cfe2ff;
  border-color: #cfe2ff;
}

.bg-secondary-light {
  background-color: #e2e3e5;
  border-color: #e2e3e5;
}

.bg-success-light {
  background-color: #d1e7dd;
  border-color: #d1e7dd;
}

.bg-danger-light {
  background-color: #f8d7da;
  border-color: #f8d7da;
}

.bg-warning-light {
  background-color: #fff3cd;
  border-color: #fff3cd;
}

.bg-info-light {
  background-color: #cff4fc;
  border-color: #cff4fc;
}

.bg-dark-light {
  background-color: #d3d3d4;
  border-color: #d3d3d4;
}

.cursor-pointer {
  cursor: pointer;
}
/* Card */
.card {
  margin-bottom: 30px;
  border: none;
  border-radius: 5px;
  box-shadow: var(--box-shadow-primary);
  background-color: var(--clr-light);
}

.card-header {
  background-color: var(--clr-light);
  padding: 15px;
}

.card-title {
  padding: 20px 0 15px 0;
  font-size: 18px;
  font-weight: 500;
  color: var(--clr-primary);
  font-family: "Poppins", sans-serif;
}

.card-body {
  padding: 0 20px 20px 20px;
}



.registration-card-title {
  display: flex;
  justify-content: center;
  padding: 20px 0 15px 0;
  font-size: 18px;
  font-weight: 500;
  color: var(--clr-primary);
  font-family: "Poppins", sans-serif;
}

/* react-calendar and date-picker*/
.react-calendar {
  width: 100%;
  max-width: 100%;
  background-color: var(--clr-light);
  border: none;
  border-radius: 5px;
  box-shadow: var(--box-shadow-primary);
  padding: 0 20px 20px 20px;
  line-height: 1.125em;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}
.react-calendar__navigation__label {
  padding: 20px 0 15px 0;
  font-size: 18px;
  font-weight: 500;
  color: var(--clr-primary);
  font-family: "Poppins", sans-serif;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}

.react-calendar__navigation button:disabled {
  background-color: var(--clr-calendar-btn-disabled);
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: var(--clr-neutral);
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font: inherit;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__month-view__weekdays__weekday,
.react-calendar__month-view__weekdays__weekday > abbr {
  text-decoration: none;
  padding: 0.5em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font: inherit;
  font-size: 0.75em;
  font-weight: 800;
}
.home-calendar .react-calendar__month-view__days__day {
  color: var(--btn-danger-bg);
  display: flex;
  justify-content: center;
}

.react-calendar__month-view__days__day > abbr {
  display: grid;
  place-items: center;
  height: 35px;
  aspect-ratio: 1/1;
  border-radius: 50%;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
  font: inherit;
  font-size: 0.833em;
  font-weight: 600;
}

.react-calendar__tile:disabled {
  color: var(--clr-neutral);
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: var(--clr-neutral);
}
.react-calendar__month-view__days__day:enabled:hover,
.react-calendar__month-view__days__day:enabled:focus {
  background-color: var(--clr-light);
}
.react-calendar__month-view__days__day:enabled > abbr:hover,
.react-calendar__month-view__days__day:enabled > abbr:focus {
  background-color: var(--clr-neutral);
}
.react-calendar__tile--now:enabled > abbr:hover,
.react-calendar__tile--now:enabled > abbr:focus {
  background: var(--clr-highlight);
  color: var(--clr-light);
}

.react-calendar__tile--hasActive {
  background-color: var(--clr-highlight);
  color: var(--clr-light);
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background-color: var(--clr-highlight);
  color: var(--clr-light);
}
.react-calendar__month-view .react-calendar__tile--active > abbr {
  background: var(--clr-light);
  border: 1px solid var(--clr-highlight);
  color: var(--clr-highlight);
}
.home-calendar .react-calendar__tile--report-submitted {
  color: var(--clr-dark);
}

.home-calendar .react-calendar__month-view__days__day--neighboringMonth {
  color: var(--clr-neutral-2);
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: var(--clr-neutral-2);
}
.react-calendar__tile--active:enabled > abbr:hover,
.react-calendar__tile--active:enabled > abbr:focus {
  background: var(--clr-highlight);
  color: var(--clr-light);
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: var(--clr-neutral);
}
.react-calendar__year-view__months__month.react-calendar__tile--active {
  background: var(--clr-highlight);
  color: var(--clr-light);
}
.date-picker .react-date-picker__wrapper {
  border: 0;
}
.date-picker {
  position: relative;
}
.date-picker .react-date-picker__calendar {
  right: 0 !important;
  left: auto !important;
}

/* Close Button */
.btn-close {
  background-size: 25%;
}

.btn-close:focus {
  outline: 0;
  box-shadow: none;
}

/*--------------------------------------------------------------
# Main
--------------------------------------------------------------*/
#main {
  margin-top: 60px;
  padding: 20px 30px;
  transition: all 0.3s;
  height: calc(100vh - 60px);
}

@media (max-width: 1199px) {
  #main {
    padding: 20px;
  }
}

/*--------------------------------------------------------------
# Page Title
--------------------------------------------------------------*/
.pagetitle {
  margin-bottom: 10px;
}

.pagetitle h1, .pagetitle h2 {
  font-size: 24px;
  margin-bottom: 0;
  font-weight: 600;
  color: var(--clr-primary);
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
.logo {
  line-height: 1;
}

.logo .logo-img, .public-logo .logo-img {
  background: url("./assets/images/logo.png");
  background-size: contain;
  background-repeat: no-repeat;
  height: 50px;
  width: 280px;
  margin-right: 6px;
}

.public-logo .toggle-sidebar-btn{
  display: none;
}

#public-sidebar{
  display: none;
}

@media (max-width: 1200px) {
  .logo .logo-img {
    width: 50px;
  }
  .logo .logo-img {
    background: url("./assets/images/mobile-logo.png");
    background-size: contain;
    background-repeat: no-repeat;
  }
  .public-nav{
    display: none !important;
  }
  .public-logo {
    flex-grow: 1;
    padding-right: 1rem;
  }
  .public-logo .toggle-sidebar-btn{
    display: block;
  }
  #public-sidebar{
    display: block;
  }
}


.header {
  transition: all 0.5s;
  z-index: 997;
  height: 60px;
  box-shadow: var(--box-shadow-primary);
  background-color: var(--clr-light);
  padding-left: 20px;
}

.header .toggle-sidebar-btn {
  font-size: 32px;
  padding-left: 10px;
  cursor: pointer;
  color: var(--clr-primary);
}

/*--------------------------------------------------------------
# Header Nav
--------------------------------------------------------------*/
.header-nav ul {
  list-style: none;
}

.header-nav > ul {
  margin: 0;
  padding: 0;
}

.header-nav .nav-icon {
  font-size: 22px;
  color: var(--clr-primary);
  margin-right: 25px;
  position: relative;
}

.header-nav .nav-profile {
  color: var(--clr-primary);
}

.header-nav .nav-profile img {
  max-height: 36px;
}

.header-nav .nav-profile span {
  font-size: 14px;
  font-weight: 600;
}
.profile-picture{
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  font-style: bold;
  background-color: var(--clr-highlight);
  color: var(--clr-light);
  box-shadow: var(--box-shadow-primary);
}
.report-profile .profile-picture, .report-profile img{
  height: 27px;
  width: 27px;
}
.header-nav .badge-number {
  position: absolute;
  inset: -2px -5px auto auto;
  font-weight: normal;
  font-size: 12px;
  padding: 3px 6px;
}
.tour-btn{
  font-size: 1.5rem
}
.tour-btn:hover{
  color: var(--clr-highlight);
}

/* Footer */
.footer {
  background-color: var(--footer-bg);
}

.footer-text {
  padding: 16px;
  font-size: 20px;
  font-weight: 800;
  color: var(--clr-primary);
}

/* Profile Dropdown */
.dropdown-list {
  background-color: var(--clr-light);
  border-radius: 4px;
  padding: 10px 0;
  animation-name: dropdown-animate;
  animation-duration: 0.2s;
  animation-fill-mode: both;
  border: 0;
  box-shadow: var(--box-shadow-primary);
}

.dropdown-list .dropdown-header {
  text-align: center;
  font-size: 15px;
  padding: 10px 25px;
}

.dropdown-list .dropdown-unit {
  background-color: var(--clr-light);
  color: var(--clr-text-primary);
  width: 100%;
  border: 0;
  font-size: 14px;
  padding: 10px 15px;
  transition: 0.3s;
}

.dropdown-list .dropdown-unit i {
  margin-right: 10px;
  font-size: 18px;
  line-height: 0;
}

.dropdown-list .dropdown-unit:hover {
  background-color: var(--clr-secondary);
}

@media (min-width: 768px) {
  .dropdown-list-arrow::before {
    content: "";
    width: 13px;
    height: 13px;
    background: var(--clr-light);
    position: absolute;
    top: -7px;
    right: 20px;
    transform: rotate(45deg);
    border-top: 1px solid var(--clr-border-primary);
    border-left: 1px solid var(--clr-border-primary);
  }
}

@keyframes dropdown-animate {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }

  0% {
    opacity: 0;
  }
}

.header-nav .profile {
  min-width: 240px;
  padding-bottom: 0;
  top: 45px !important;
}

.header-nav .profile .dropdown-header h6 {
  font-size: 18px;
  margin-bottom: 0;
  font-weight: 600;
  color: var(--clr-text-primary);
}

.header-nav .profile .dropdown-header span {
  font-size: 14px;
}

.header-nav .profile .dropdown-unit {
  font-size: 14px;
  padding: 10px 15px;
  transition: 0.3s;
}

.header-nav .profile .dropdown-unit i {
  margin-right: 10px;
  font-size: 18px;
  line-height: 0;
}

.header-nav .profile .dropdown-unit:hover {
  background-color: var(--clr-secondary);
}

.profile hr {
  margin: 0;
}

/*--------------------------------------------------------------
# Sidebar
--------------------------------------------------------------*/
.sidebar {
  position: fixed;
  top: 60px;
  left: 0;
  bottom: 0;
  width: 300px;
  z-index: 996;
  transition: all 0.3s;
  padding: 20px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: var(--clr-scrollbar) transparent;
  box-shadow: var(--box-shadow-primary);
  background-color: var(--clr-light);
}

@media (max-width: 1199px) {
  .sidebar {
    left: -300px;
  }
}

.sidebar::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: var(--clr-light);
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: var(--clr-scrollbar);
}

@media (min-width: 1200px) {
  #main,
  #footer {
    margin-left: 300px;
  }
}

@media (max-width: 1199px) {
  .toggle-sidebar .sidebar {
    left: 0;
  }
}

@media (min-width: 1200px) {
  .toggle-sidebar #main,
  .toggle-sidebar #footer {
    margin-left: 0;
  }

  .toggle-sidebar .sidebar {
    left: -300px;
  }
}

.sidebar-nav {
  padding: 0;
  margin: 0;
  list-style: none;
}

.sidebar-nav li {
  padding: 0;
  margin: 0;
  list-style: none;
}

.sidebar-nav .nav-item {
  margin-bottom: 5px;
}

.sidebar-nav .nav-heading {
  font-size: 11px;
  text-transform: uppercase;
  color: var(--clr-highlight-2);
  font-weight: 600;
  margin: 10px 0 5px 15px;
}

.sidebar-nav .nav-link {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  color: var(--clr-highlight);
  transition: 0.3;
  background: var(--clr-secondary);
  padding: 10px 15px;
  border-radius: 4px;
}

.sidebar-nav .nav-link i {
  font-size: 16px;
  margin-right: 10px;
  color: var(--clr-highlight);
}

.sidebar-nav .nav-link.collapsed {
  color: var(--clr-primary);
  background: var(--clr-light);
}

.sidebar-nav .nav-link.collapsed i {
  color: var(--clr-highlight-2);
}

.sidebar-nav .nav-link:hover {
  color: var(--clr-highlight);
  background: var(--clr-secondary);
}

.sidebar-nav .nav-link:hover i {
  color: var(--clr-highlight);
}

.sidebar-nav .nav-link .bi-chevron-down {
  margin-right: 0;
  transition: transform 0.2s ease-in-out;
}

.sidebar-nav .nav-link:not(.collapsed) .bi-chevron-down {
  transform: rotate(180deg);
}

.sidebar-nav .nav-content {
  padding: 5px 0 0 0;
  margin: 0;
  list-style: none;
}

.sidebar-nav .nav-content a {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: var(--clr-primary);
  transition: 0.3;
  padding: 10px 0 10px 40px;
  transition: 0.3s;
}

.sidebar-nav .nav-content a i {
  font-size: 6px;
  margin-right: 8px;
  line-height: 0;
  border-radius: 50%;
}

.sidebar-nav .nav-content a:hover,
.sidebar-nav .nav-content a.active {
  color: var(--clr-highlight);
}

.sidebar-nav .nav-content a.active i {
  background-color: var(--clr-highlight);
}

/*--------------------------------------------------------------
# Body
--------------------------------------------------------------*/

/* Dashboard Page */
/* Info Cards */
.dashboard .info-card {
  padding-bottom: 10px;
}

.dashboard .info-card h6 {
  font-size: 28px;
  color: var(--clr-primary);
  font-weight: 700;
  margin: 0;
  padding: 0;
}

.dashboard .card-icon {
  font-size: 32px;
  line-height: 0;
  width: 64px;
  height: 64px;
  flex-shrink: 0;
  flex-grow: 0;
}

/* My Reports Page */
.my-reports .button,
.users .button,
.subordinate-reports .button,
.modal-content .button,
.report-details .button,
.my-time-sheet .button,
.subordinate-time-sheet .button {
  background-color: transparent;
  border: 0;
  padding-block: 0;
}

/* filters */
.filter {
  z-index: 10;
  margin-bottom: 10px;
  border-radius: 4px;
  background: var(--filter-bg);
}
.filter select {
  background-color: var(--filter-bg);
  border: 0;
}
.filter select:focus {
  border: 0;
  outline: 0;
}
.report-form,
#components-nav {
  animation-name: dropdown-animate;
  animation-duration: 0.2s;
  animation-fill-mode: both;
}
.filter-btn {
  font-size: 24px;
  color: var(--clr-text-primary);
}
.filter-btn-active {
  color: var(--clr-highlight);
}
.create-btn,
.filter-close-btn,
.task-delete-btn {
  font-size: 20px;
  color: var(--btn-danger-bg);
}

.my-reports .card-body, .subordinate-reports .card-body {
  padding: 0 20px;
}

.report-heading {
  font-size: 18px;
  font-weight: 500;
  color: var(--clr-primary);
  font-family: "Poppins", sans-serif;
}
.task-block {
  box-shadow: var(--box-shadow-primary);
  font-family: "Poppins", sans-serif;
  background: var(--clr-light);
  margin-bottom: 20px;
}
.task-block .task-title {
  padding: 7px;
  font-size: 16px;
  background-color: var(--clr-highlight);
  color: var(--clr-light);
}
.add-task-btn {
  margin-block: 15px;
  color: var(--clr-highlight);
  font-size: 20px;
  font-weight: 500;
}
#submit-btn,
#back-btn,
#logout-btn {
  font-size: 16px;
  font-weight: 600;
  background-color: var(--btn-primary-bg);
  color: var(--clr-light);
  box-shadow: var(--box-shadow-primary);
}

/* Comment Input Send button */
.send-btn {
  font-size: 12px;
  background-color: var(--btn-primary-bg);
  color: var(--clr-light);
  border: 0;
  margin-right: 8px;
  padding: 8px 12px;
  border-radius: 40px;
  box-shadow: var(--box-shadow-primary);
}

#secondary-btn{
  font-size: 16px;
  font-weight: 600;
  background-color: var(--btn-secondary-bg);
  color: var(--clr-light);
  box-shadow: var(--box-shadow-primary);
}
#delete-btn{
  font-size: 16px;
  font-weight: 600;
  background-color: var(--btn-danger-bg);
  color: var(--clr-light);
  box-shadow: var(--box-shadow-primary);
}

/* Report Details page */
#back-btn{
  align-self:center;  
}
.report-details{
  height: calc(100vh - 60px);
  display:flex;
  flex-direction:column;
}

/* Users */
.table.users-table * {
  font-family: "Poppins", sans-serif;
  background: inherit;
}
.users-table .actions {
  color: var(--clr-highlight);
}
.filter-div{
  border-radius: 4px;
}
.filter-div:focus-within {
  border-color: var(--clr-border-input-focus);
  outline: 0;
  box-shadow: var(--box-shadow-input-focus);
}
.filter-input{
  max-width: 20rem; 
  background-color: var(--filter-bg);
}
.filter-input:focus{
  background-color: var(--filter-bg);
  outline: 0;
}
.filter-dropdown {
  background-color: inherit;
  min-width: 12rem;
  text-align: left;
  white-space: nowrap;
}
.restrict-width {
  max-width: 12rem;
}
.filter-dropdown-list {
  background-color: var(--clr-light);
  position: absolute;
  z-index: 1;
  width: 100%;
  max-height: 20rem;
  overflow-y: auto;
  box-shadow: var(--box-shadow-primary);
}
.filter-dropdown-list[data-direction="up"] {
  bottom: 2.35rem;
}
.filter-dropdown-list[data-direction="down"] {
  top: 2.35rem;
}
.filter-dropdown-list ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

/* Home and Menu Page */
.home {
  margin: 0;
  padding: 0;
}

.home-text{
  font-size: 20px;
  font-weight: 400;
  font-family: 'Nunito', sans-serif;
  line-height: 24px;
  text-align: justify;
  margin: 0;
}

.faq-container {
  display: flex;
  height: max-content;
  justify-content: center;
  align-items: center;
}

.faq-item {
  background-color: var(--clr-light);
  border: 2px solid var(--clr-border-primary);
  margin-bottom: 8px;
  border-radius: 8px;
}

.title-active {
  color: var(--clr-light);
  background-color: var(--clr-highlight);
  border-radius: 8px 8px 0px 0px;
  padding: 20px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.title {
  color: var(--clr-text-primary);
  border-radius: 8px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.content {
  color: var(--clr-text-primary);
  max-height: 0px;
  overflow: hidden;
}

.content-show {
  color: var(--clr-text-primary);
  height: auto;
  padding: 20px 20px;
  overflow: hidden;
  max-height: 9999px;
}

/* Tooltip */
.tooltip-right {
  position: relative;
  display: inline-block;
}

.tooltip-right .tooltiptext {
  display: none;
  font-size: 12px;
  width: 200px;
  background-color: var(--clr-dark);
  color: var(--clr-light);
  text-align: center;
  border-radius: 5px;
  padding: 5px 5px;
  margin-left: 10px;
  position: absolute;
  z-index: 10;
}
.tooltiptext-arrow::before {
  content: "";
  position: absolute;
  top: 8px;
  right: 100%;
  border: 5px solid;
  border-color: transparent var(--clr-dark) transparent transparent;
}
.tooltip-right:hover{
  color: var(--clr-highlight);
}
.tooltip-right:hover .tooltiptext {
  display: inline;
}

/* tour */
.tour-popover {
  border-radius: 1rem;
}

@media (max-width: 768px) {
  .tooltip-right .tooltiptext {
    margin-left: 10px;
    right: -15px;
    bottom: 130%;
  }
  .tooltiptext-arrow::before {
    top: 100%;
    right: 18px;
    border-color: var(--clr-dark) transparent transparent transparent;
  }
}

.invalid-field{
  outline: 2px solid var(--btn-danger-bg) !important;
}
