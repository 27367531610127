.alert-container {
  width: calc(100vw - 300px);
  position: fixed;
  bottom: 0;
  z-index: 100;
  padding: 20px 30px;
}
.alert-body {
  border: none;
  border-radius: 5px;
  box-shadow: var(--box-shadow-primary);
  font-family: "Poppins", sans-serif;
}
.alert-success {
  background-color: var(--clr-success-alert-bg);
  color: var(--clr-success-alert-text);
}
.alert-error {
  background-color: var(--clr-error-alert-bg);
  color: var(--clr-error-alert-text);
}
.alert-heading {
  padding: 20px 0 15px 0;
  font-weight: 500;
  font-size: 20px;
}
.alert-message {
  padding-left: 30px;
}
@media (min-width: 1200px) {
  .alert-container {
    margin-left: 300px;
  }
}
