.hourglass-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: var(--clr-light);

  .hourglass {
    display: inline;
    width: 200px;
    height: 200px;
  }
}