.time-dropdown-list {
  width: 10rem;
}

.time-dropdown ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.time-dropdown hr {
  margin: 0;
}

.time-btn {
  font-size: 20px;
  color: var(--clr-highlight);
}
