.time-sheet-table {
  width: 100%;
  border-collapse: collapse;
}

.time-sheet-table td {
  border: 0.8px solid #dee2e6;
}

.time-sheet-table th {
  padding: 0.5rem;
  background-color: #4154f1;
  color: #ffffff;
}
.time-sheet-table th {
  min-width: 10rem;
}
.time-sheet-table > thead > tr > th:first-child {
  max-width: 20%;
  min-width: 20%;
  width: 20%;
  border: 0.8px solid #dee2e6;
  border-width: 0 0.8px 0 0;
  border-radius: 0.5rem 0 0 0;
}
.time-sheet-table > thead > tr > th:last-child {
  max-width: 10%;
  min-width: 10%;
  width: 10%;
  border: 0.8px solid #dee2e6;
  border-width: 0 0 0 0.8px;
  border-radius: 0 0.5rem 0 0;
}
.date-cell {
  width: 30px;
  padding: 0.3rem;
  text-align: center;
  vertical-align: center;
}
.download-button {
  font-size: 1.25rem;
  color: #ffffff;
  font-weight: bold;
}
